<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #f2c98a">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img :src="$assetURL('media/logos/logo-tt.png')" class="max-h-70px" alt="" />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #986923"
          >
            Discover Amazing Treasure Trove <br />with great build tools
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                  Welcome to Treasure Trove
                </h3>
              </div>
              <div v-if="errorMessage">
                <p class="alert alert-danger">{{ errorMessage }}</p>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    :disabled="isLoading"
                    v-model="form.email_or_phone"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    :disabled="isLoading"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  :disabled="isLoading"
                  type="button"
                  v-on:click="submitLogin"
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                  Forgotten Password ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your email to reset your password
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  :disabled="isLoading"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  :disabled="isLoading"
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a href="#" class="text-primary font-weight-bolder font-size-h5">Terms</a>
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Plans</a>
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Contact Us</a>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import KTUtil from "@/assets/js/components/util";
import { mapState } from "vuex";
import { PROFILE, LOGIN, LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      isLoading: false,
      errorMessage: null,
      form: {
        email_or_phone: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg";
    },
  },
  methods: {
    submitLogin() {
      const _this = this;

      _this.$store.dispatch(LOGOUT);

      const submitButton = _this.$refs["kt_login_signin_submit"];

      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      _this.isLoading = true;
      _this.errorMessage = null;
    
      _this.$store
        .dispatch(LOGIN, _this.form)
        .then(() => {
     
          _this.$store
            .dispatch(PROFILE)
            .then(() => {
            
              const resolved = _this.$router.resolve({
                name: "customer",
                query: { t: new Date().getTime() },
              });
              window.location.href = resolved.href;
            })
            .catch(() => {
              _this.$store.dispatch(LOGOUT);
            });
        })
        .catch((error) => {
      
          _this.errorMessage = error;
          _this.isLoading = false;
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        })
        .finally(() => {
          

          _this.isLoading = false;
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(KTUtil.getById(form_name), "animate__animated animate__backInUp");
    },
  },
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.form.email_or_phone = process.env.VUE_APP_ADMIN_EMAIL;
      this.form.password = process.env.VUE_APP_ADMIN_PASSWORD;
    }
  },
};
</script>
